import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LookUpElement } from 'src/app/shared/models/common-data.model';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { LookupImageModel } from 'src/app/home/master/driver-type-class-assign/models/driver-type-class-assign.model';

@Injectable({
  providedIn: 'root'
})
export class BaseDataService {

  baseUrl: string;
  private token: string;
  private lang : string="en"
  private companyId : string=""
  private company : LookUpElement;

  constructor(
    private http: HttpClient,
    // private authService :AuthService
    ){
    this.baseUrl = environment.baseUrl;
    this.lang=localStorage.getItem('lang') ?? 'en'

    this.getCompanyId()


    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === 'currentCompany') {
        const currentCompany = event.newValue;
        if (currentCompany === null) {

        } else {
          var el = localStorage.getItem("currentCompany");


          if(el){
            this.companyId=JSON.parse(el)?.id ?? ''
          }

          // Handle the case when 'currentUser' is not null


        }
      }

    });
    // this.company=this.authService.currentCompany
  }

  getCompanyId(){
    var el = localStorage.getItem("currentCompany");
    if(el){
      this.companyId=JSON.parse(el)?.id ?? ''
    }
  }

  getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  makeGetCall(path: string): Observable<any> {
    this.getCompanyId()
    let headers = new HttpHeaders({'Authorization': 'bearer ' + this.getToken() ,'lang':this.lang ,'companyId':this.companyId ?? '', timeZone: this.getTimeZone() });
    return this.http.get(this.constructUrl(path), { headers: headers})
  }


  makePostCall(path: string, body: any): Observable<any> {
    this.getCompanyId()
    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'bearer ' + this.getToken(),'lang':this.lang,'companyId':this.companyId ?? '', timeZone: this.getTimeZone()});
    return this.http.post(this.constructUrl(path), bodyJson, { headers: headers })
  }


  makePostCallImageUpload(path: string, form: any): Observable<any> {
    return this.http.post(this.constructUrl(path), form)
  }

  makePostDownloadCall(path: string, body: any): Observable<any> {
    this.getCompanyId()
    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'bearer ' + this.getToken(),'lang':this.lang ,'companyId':this.companyId ?? '', timeZone: this.getTimeZone()});
    return this.http.post(this.constructUrl(path), bodyJson, { headers: headers , responseType: 'blob'});
  }
  makePutCall(path: string, body: any): Observable<any> {
    this.getCompanyId()
    let bodyJson = JSON.stringify(body);
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Authorization': 'bearer ' + this.getToken(),'lang':this.lang,'companyId':this.companyId ?? '', timeZone: this.getTimeZone() });
    return this.http.put(this.constructUrl(path), bodyJson, { headers: headers })
  }



  makeDeleteCall(path: string): Observable<any> {

    this.getCompanyId()
    let headers = new HttpHeaders({'Authorization': 'bearer ' + this.getToken() , 'lang':this.lang,'companyId':this.companyId ?? ''});
    return this.http.delete(this.constructUrl(path), { headers: headers})
  }

  private getToken() {
    return localStorage.getItem('token');
  }

 private get getCurrentCompanyValue(): LookupImageModel {


    var el = localStorage.getItem("currentCompany") ?? ''

    console.log(el,'elelelelel');


    return el!=el ?  JSON.parse(el) : null ;
  }



  private constructUrl(path: string) {
    return this.baseUrl + path;
  }
}
