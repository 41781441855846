import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { DataTableColumn } from 'src/app/shared/models/data-table.model';
import { FilterData } from 'src/app/shared/models/filter-data.model';
import { FilterSharedService } from 'src/app/shared/services/filter-shared.service';

@Component({
  selector: 'app-filter-column',
  templateUrl: './filter-column.component.html',
  styleUrls: ['./filter-column.component.scss']
})
export class FilterColumnComponent implements OnDestroy, AfterViewInit {
  @ViewChild('template', { static: true }) template: any;
  modalRef: BsModalRef;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  filterloadDataEvent = new BehaviorSubject(null);
  filterDataSubject: Subscription;
  filterData: FilterData;
  sortForm: FormGroup;
  coloumnsData: DataTableColumn[];

  @Input() filterForm: FormGroup;
  /**
   *
   */
  constructor(private modalService: BsModalService, private filterSharedService: FilterSharedService, private fb: FormBuilder) {
    this.filterDataSubject = filterSharedService.getsubjectFilterData().pipe(takeUntil(this.destroy))
      .subscribe((filterData: FilterData) => {
        if (filterData != null) {
          this.filterData = filterData;
          this.coloumnsData = filterData.ColumnsData;
        }
      });

    this.filterDataSubject = filterSharedService.getsubjectColumnOpenModal().pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: any) => {
        if (sortByOpen != null && sortByOpen == 'open') {
          this.showModal()
        }
      });

    this.filterDataSubject = filterSharedService.getsubjectFilterReset().pipe(takeUntil(this.destroy))
      .subscribe((sortByOpen: boolean) => {
        if (sortByOpen != null && sortByOpen) {

         this.resetFilter()
        }
      });

  }
  ngAfterViewInit(): void {

  }


  ngOnInit() {
    this.initializeForm()
  }
  initializeForm() {
    var formvalues = [];
    this.coloumnsData.forEach(element => {

      formvalues.push(new FormControl(element.data))
    });

    this.filterForm?.addControl("columns", new FormArray(formvalues));

  }
  private showModal() {
    // this.offerForm.get("operationalHours").setValue(this.fb.array([]));
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right '
    });
  }


  searchChange(event: any) {


    const filterValue = event.target.value.toLowerCase();

    this.coloumnsData = this.filterData.ColumnsData.filter(option => option.title.toLowerCase().includes(filterValue));
  }
  resetFilter() {

    var checkArray: FormArray = this.filterForm.get('columns') as FormArray;


    var arr = this.coloumnsData.map(a => a.data);

    checkArray.clear()

    console.log(checkArray);


    this.coloumnsData.forEach(element => {

      checkArray.push(new FormControl(element.data,))
    });
    // this.filterSharedService.setsubjectApplyFilter(true)


  }
  onCheckboxChange(e) {

    console.log(e);



    var checkArray: FormArray = this.filterForm.get('columns') as FormArray;

    var arr = this.coloumnsData.map(a => a.data);

    if (e.checked && !checkArray.value.includes(e.source.id)) {

      checkArray.insert(arr.indexOf(e.source.id), new FormControl(e.source.id));

    } else {

      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.source.id) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

  }
  drop(event: CdkDragDrop<string[]>) {
    var checkArray: FormArray = this.filterForm.get('columns') as FormArray;
    moveItemInArray(this.coloumnsData, event.previousIndex, event.currentIndex);
    this.mapColoumns()
  }
  mapColoumns(){
    var formvalues = [];
    var checkArray: FormArray = this.filterForm.get('columns') as FormArray;
    console.log(checkArray.controls);

    const values = checkArray.controls.map(control => control.getRawValue());
    this.coloumnsData.forEach(element => {

      if (values.includes(element.data)) {

        formvalues.push(new FormControl( element.data))
      }
    });


    this.filterForm?.setControl("columns", new FormArray(formvalues));
  }

  applyFilter() {
    this.filterSharedService.setsubjectApplyFilter(true)
  }
  ngOnDestroy() {
    this.filterSharedService.setsubjectColumnOpenModal(null)
    this.destroy.next(null);
    this.modalRef?.hide();
  }
}
