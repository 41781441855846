// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   baseUrl: 'https://fleetapi.codezync.net/',
//   authUrl:"http://localhost:8080",
//   clientId:"FleetClientApi",
//   clientSecret:"xY1qMBOyls0qbhdVIl8ZgcVvStkN5Q9l",
//   clientDBId:"ae095528-6176-4e48-842a-6f1652f52840",
//   realm:"Fleet"

// };


export const environment = { //demo servewr
  production: false,
  baseUrl: 'https://api.fleet.uat.codezync.net/',
  authUrl:"https://kcauth.codezync.net",
  clientId:"fleet-uat-client",
  clientSecret:"noiJ9h9TnLmXl1yxr1YUiPf5S1upnRPr",
  signalRUrl:"https://api.fleet.uat.codezync.net/notificationHub",
  clientDBId:"5bd0c767-50c1-4da9-b419-f056a2ec986a",
  realm:"fleet-uat-realm",
  // pmsBaseUrl:"https://ro-op-uat.gears-int.com/"
  pmsBaseUrl:"https://pms-qa.gears-int.com/api/v1/",
  portalBaseUrl:"https://qa.pl.uat-gears-int.com/api/v1/",
  googleMapsApiKey:"AIzaSyCpmo1d7ObB7rB82TWjP-dDyMMr1eW3CR4"

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
