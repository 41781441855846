<div class="row mt-3 flex-row-reverse">
  <div
    class="col-xl-2 col-lg-2 col-md-4 col-sm-12 mb-2 d-flex flex-row-reverse justify-content-between align-items-baseline filter-section-mg">
    <!-- <a mat-raised-button  class="filter-info-btn btn-border btn-text-color btn-bg-color btn-shadow-hide">+ Add Vehicle</a> -->
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" matTooltip="Clear filters"
      class="d-flex justify-content-center align-items-center" (click)="resetFilter()" *ngIf="enableReset">
      <i class="material-icons-outlined filter-btn-icon icon-font-size">restart_alt</i>
    </button>
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" matTooltip="{{'FILTER_MENU.FILTERS' | translate}}"
      class="d-flex justify-content-center align-items-center {{fileterValues?.FilterValues?.length > 0 ? 'icon-selcected' : ''}}" (click)="openParameterModal()">
      <i class="material-icons-outlined filter-btn-icon icon-font-size">filter_alt</i>
    </button>
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" matTooltip="{{'FILTER_MENU.COLUMNS' | translate}}"
      class="d-flex justify-content-center align-items-center" (click)="openColoumnModal()">
      <i class="material-icons-outlined filter-btn-icon icon-font-size">view_column</i>
    </button>
    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon " matTooltip="{{'FILTER_MENU.SORT_BY' | translate}}"
      class="d-flex justify-content-center align-items-center  {{isSortByEnabled(this.fileterValues)? 'icon-selcected' : ''}}" (click)="openSortByModal()">
      <i class="material-icons-outlined filter-btn-icon icon-font-size">sort_by_alpha</i>
    </button>

    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" matTooltip="{{'FILTER_MENU.GROUP_BY' | translate}}"
      class="d-flex justify-content-center align-items-center {{this.fileterValues?.groupBy!=null ? 'icon-selcected' : ''}} " (click)="openGroupByModal()">
      <i class="material-icons-outlined filter-btn-icon icon-font-size">table_rows</i>
    </button>
    <form [formGroup]="filterForm">
      <mat-form-field class="example-full-width search-input" appearance="">
        <mat-label>{{'FILTER_MENU.SEARCH' | translate}}</mat-label>
        <input matInput formControlName="search" (input)="changeSearchInput()" />
        <mat-icon matSuffix class="face-icon p-0">search</mat-icon>
      </mat-form-field>
    </form>

    <!-- <a mat-raised-button  class="filter-info-btn btn-border btn-text-color btn-bg-color btn-shadow-hide">Actions</a> -->
  </div>
  <!-- <div
    class="col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-2 d-flex flex-row-reverse"
  >
  <form [formGroup]="filterForm">
    <mat-form-field class="example-full-width search-input" appearance="">
      <mat-label>Search</mat-label>
      <input matInput formControlName="search" />
      <mat-icon matSuffix class="face-icon">search</mat-icon>
    </mat-form-field>
  </form>
  </div> -->
</div>


<app-filter-sorting [filterForm]="filterForm"></app-filter-sorting>
<app-filter-column [filterForm]="filterForm"></app-filter-column>
<app-filter-group [filterForm]="filterForm"></app-filter-group>
<app-filter-parameters [filterForm]="filterForm"></app-filter-parameters>